const { defaultLanguage } = require("../../prismic-configuration");

/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage": {
      return doc.lang === defaultLanguage
        ? `/`
        : doc.lang === `en-gb`
        ? `/`
        : doc.lang === `et-eu`
        ? `/et/`
        : doc.lang === `ar-ae`
        ? `/ar/`
        : doc.lang === `ru`
        ? `/ru/`
        : doc.lang === `es-es`
        ? `/es/`
        : `/${doc.lang}`;
    }
    case "privacy_policy": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : doc.lang === `en-gb`
        ? `/${doc.uid}`
        : doc.lang === `et-eu`
        ? `/et/${doc.uid}`
        : doc.lang === `ar-ae`
        ? `/ar/${doc.uid}`
        : doc.lang === `ru`
        ? `/ru/${doc.uid}`
        : doc.lang === `es-es`
        ? `/es/${doc.uid}`
        : `/${doc.lang}/${doc.uid}`;
    }
    case "terms_and_conditions": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : doc.lang === `en-gb`
        ? `/${doc.uid}`
        : doc.lang === `et-eu`
        ? `/et/${doc.uid}`
        : doc.lang === `ar-ae`
        ? `/ar/${doc.uid}`
        : doc.lang === `ru`
        ? `/ru/${doc.uid}`
        : doc.lang === `es-es`
        ? `/es/${doc.uid}`
        : `/${doc.lang}/${doc.uid}`;
    }
    case "support": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : doc.lang === `en-gb`
        ? `/${doc.uid}`
        : doc.lang === `et-eu`
        ? `/et/${doc.uid}`
        : doc.lang === `ar-ae`
        ? `/ar/${doc.uid}`
        : doc.lang === `ru`
        ? `/ru/${doc.uid}`
        : doc.lang === `es-es`
        ? `/es/${doc.uid}`
        : `/${doc.lang}/${doc.uid}`;
    }

    default:
      return "/";
  }
};
